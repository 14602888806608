import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/user';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

    @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
    @Input() sidebarOpened: boolean;
    public user: User = new User();
    public production = environment.production;
    public environment_name = environment.environment_name;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        this.user = this.authService.getAuth();
    }

    toogleSidenav() {
        this.toggleSidebar.emit();
    }
}
